import React, { useState, useEffect } from 'react';
import { HiOutlineClipboardDocumentCheck } from "react-icons/hi2";
import { HiMiniClipboardDocumentCheck } from "react-icons/hi2";
import { GrDocumentTime } from "react-icons/gr";
import { SiConvertio } from "react-icons/si";
import { FaRegHandshake } from "react-icons/fa6";
import { FaHandHoldingUsd } from "react-icons/fa";
import { TbSettingsCheck } from "react-icons/tb";
import { TbSettingsShare } from "react-icons/tb";
import { GiReceiveMoney } from "react-icons/gi";
import { FaRegHeart } from "react-icons/fa";
import { BiHomeHeart } from "react-icons/bi";
import { FaCar } from "react-icons/fa";
import { FaBookOpenReader } from "react-icons/fa6";
import { RiHealthBookFill } from "react-icons/ri";
import { GiLovers } from "react-icons/gi";
import { GrCreditCard } from "react-icons/gr";
import { MdPhoneIphone } from "react-icons/md";
import { MdArrowCircleRight } from "react-icons/md";
import ChooseUsImg from "../../assets/choose-us1.png";
import "./home.css";
import Calculator from './Calculator';
function Home() {

  const Caraousel = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }
    const prevSlide = () => {
      setCurrentIndex((prevIndex) => prevIndex === 0 ? images.length - 1 : prevIndex - 1);
    }

    useEffect(() => {
      const intervalId = setInterval(() => {
        nextSlide()
      }, 2000)
      return () => clearInterval(intervalId)
    }, [currentIndex])
    return (
      <section className='landing-section'>
        <div className='landing-div'>
          {/* <button className='btn btn-primary' onClick={prevSlide}>Previous</button> */}
          <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} className='landing-image' />
          {/* <div className='home-banner'>
        <div className='banner-text'>
          <p> We Are Loan Bazzar </p>
          <h4> A Smarter And Secure Way Of Getting Loans </h4>
        </div>
      </div> */}
          {/* <button className='btn btn-primary' onClick={nextSlide}> Next </button> */}
        </div>

      </section>
    )
  }

  const imageUrls = [
    'https://media.istockphoto.com/id/1070310624/photo/young-tourist-woman-pulls-her-hand-to-the-night-fire-on-the-beach.webp?s=170667a&w=0&k=20&c=432QvDS4k-S2TO6t77v3DXi_yH8oQTTk_zZsGwvL8sM=',
    'https://media.istockphoto.com/id/695955364/photo/loan-concept.jpg?s=1024x1024&w=is&k=20&c=8qraRM69JAMDF03LxOvNqJ0oSekNgMSXVyGullwcHUA=',
    'https://media.istockphoto.com/id/1449789277/photo/advice-and-financial-review-stock-photo.jpg?s=1024x1024&w=is&k=20&c=Q7cb-oE6lRoFCUplpJSpdv98sZtrN86QhotDIqpQlss=',
    'https://media.istockphoto.com/id/995746324/photo/businessman-giving-money-indian-rupee-currency-to-his-partner.jpg?s=1024x1024&w=is&k=20&c=EPvQhBXD46qRo1n0DIxyLgbsPqhMLxCSIT8EI-3TIv4='
  ]


  return (
    <>
      <Caraousel images={imageUrls} />
      <div className='main-div-home'>
        <div className='container text-center'>
          <div className='exact-down-home'>
            <h3>3 Simple Steps To Solve All Your Money-Related Problems</h3>
            <p>We yearn to make this process easier, faster and safer for you in every possible way!</p>
            <div className='row'>
              <div className="col-lg-4">
                <div className='first-col-loan'>
                  <div><HiOutlineClipboardDocumentCheck /> </div>
                  <h5>Fast Loan Approvals</h5>
                  <div className='hr-simple'></div>
                  <span>It is the fastest yet secure way to avail of personal loan.</span>
                </div>
              </div>
              <div className="col-lg-4">
                <div className='first-col-loan'>
                  <div><GrDocumentTime /> </div>
                  <h5>Easy To Apply</h5>
                  <div className='hr-simple'></div>
                  <span>Minimum documentation, and simple to apply. Processing within a minute after applying.</span>
                </div>
              </div>
              <div className="col-lg-4">
                <div className='first-col-loan'>
                  <div><SiConvertio /> </div>
                  <h5>Get Instant Funds</h5>
                  <div className='hr-simple'></div>
                  <span>We shared all the information with the borrower and no hidden charge. </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='loan-calculator'>
          <h4>Personal Loan Calculator</h4>
          <div className='hr-simple'></div>
<Calculator />
        </div>
      </div>


      <div className='main-div-home'>
        <div className='container'>
          <div className='loan-feature'>
            <h4>Best features of our loan offerings</h4>
            <div className='hr-tag-offer'></div>
            <div className='row'>
              <div className="col-lg-4">
                <div className='first-col-loan loan-offering'>
                  <div><FaRegHandshake /> </div>
                  <h5>Swift Approval</h5>
                  <div className='hr-loan-offering'></div>
                  <span>We believe in saving your valuable time and quick money compliance by using our upgraded software. After successfully checking your documents, we approve your loan within minutes and in no time it gets transferred to your account!</span>
                </div>
              </div>
              <div className="col-lg-4">
                <div className='first-col-loan loan-offering'>
                  <div><TbSettingsCheck /> </div>
                  <h5>Flexible Payment Terms</h5>
                  <div className='hr-loan-offering'></div>
                  <span>We accept payments through simple flexible methods, be it easy pay or bank transfer. It aims to enhance your lifestyle experience without worrying about the scarcity of funds!</span>
                </div>
              </div>
              <div className="col-lg-4">
                <div className='first-col-loan loan-offering'>
                  <div><GiReceiveMoney /> </div>
                  <h5>Transparent Pricing</h5>
                  <div className='hr-loan-offering'></div>
                  <span>We offer competitive interest rates and APRs that are fair and all costs are truthfully disclosed to the customer. Our policy is to make life fulfilling for you and your family by adding quality time to each and every moment. </span>
                </div>
              </div>

              <div className="col-lg-4">
                <div className='first-col-loan loan-offering'>
                  <div><TbSettingsShare /> </div>
                  <h5>Completely Secure Process</h5>
                  <div className='hr-loan-offering'></div>
                  <span>We have separate teams at each level to check the transfer of funds in the most transparent and secure manner to keep away any kind of foul play. Your dream is our dream and we will keep it safe and protected!</span>
                </div>
              </div>
              <div className="col-lg-4">
                <div className='first-col-loan loan-offering'>
                  <div><FaHandHoldingUsd /> </div>
                  <h5>Minimum Documentation</h5>
                  <div className='hr-loan-offering'></div>
                  <span>Paperless Documentation which is 100% online and stress-free. Your personal loan no longer requires visiting the bank and signing bulky bundles of documents. Bharat Loan provides quick, easy and simple digital solutions to all your short-term monetary distress!</span>
                </div>
              </div>
              <div className="col-lg-4">
                <div className='first-col-loan loan-offering'>
                  <div><HiMiniClipboardDocumentCheck /> </div>
                  <h5>Instant Disbursal</h5>
                  <div className='hr-loan-offering'></div>
                  <span>After verification of your documents, we transfer your approved amount instantly. We care about fast delivery and an immediate relief from your financial worries! Advanced technology will be your constant partner as our customer.</span>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>


      {/* No Money! no problem */}

      <div className='container'>
        <div className='loan-calculator' >
          <h4>No Money? No Problem</h4>
          <div className='hr-simple mb-5'></div>
          <div className='row'>
            <div className='col-lg-3'>
              <div className='loan-service-box text-center'>
                <FaRegHeart />
                <h4>Personal Loan</h4>
              </div>
            </div>
            <div className='col-lg-3'>
              <div className='loan-service-box text-center'>
                <BiHomeHeart />
                <h4>Home Loan</h4>
              </div>
            </div>
            <div className='col-lg-3'>
              <div className='loan-service-box text-center'>
                <FaCar />
                <h4>Car Loan</h4>
              </div>
            </div>
            <div className='col-lg-3'>
              <div className='loan-service-box text-center'>
                <FaBookOpenReader />
                <h4>Education Loan</h4>
              </div>
            </div>
            <div className='col-lg-3'>
              <div className='loan-service-box text-center'>
                <RiHealthBookFill />
                <h4>Health Loan</h4>
              </div>
            </div>
            <div className='col-lg-3'>
              <div className='loan-service-box text-center'>
                <GiLovers />
                <h4>Wedding Loan</h4>
              </div>
            </div>
            <div className='col-lg-3'>
              <div className='loan-service-box text-center'>
                <GrCreditCard />
                <h4>Credit Card</h4>
              </div>
            </div>
            <div className='col-lg-3'>
              <div className='loan-service-box text-center'>
                <MdPhoneIphone />
                <h4>Phone Loan</h4>
              </div>
            </div>

          </div>
        </div>
      </div>


      {/* Fast Service During Emergency */}
      <div className='choose-us-home'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='fast-service'>
                <h4>Fast Service During Emergency</h4>
                <p>We provide an instant loan in a few hours because our professional and experienced staff provide you with the best services in an emergency loan requirement. Less paperwork and quick approval of the amount.</p>
                <ul>
                  <li className='d-flex'>
                    <strong><MdArrowCircleRight />We provide a loan when your need is greatest.</strong>
                  </li>
                  <li className='d-flex'>
                    <strong><MdArrowCircleRight />We have simple documentation procedure.</strong>
                  </li>
                  <li className='d-flex'>
                    <strong><MdArrowCircleRight />100% Privacy and Transparency.</strong>
                  </li>
                  <li className='d-flex'>
                    <strong><MdArrowCircleRight />Reaching you the loan in time.</strong>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='choose-us-img'>
                <img src={ChooseUsImg} alt='' />
              </div>
            </div>
          </div>
        </div>

      </div>

      {/* Documents Required & Eligibility Criteria */}
      <div className='document-system'>
        <div className='container'>
          <h3>Documents Required & Eligibility Criteria</h3>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='eligibility-first'>
                <h5>ELIGIBILITY CRITERIA</h5>
                <hr />
                <ul>
                  <li>Indian resident.</li>
                  <li>Salaried Employee/Business Owner/Self Employed.</li>
                  <li>Above 21 years of age.</li>
                  <li>Saving Bank Account Holder.</li>
                  <li>Fair CIBIL Score.</li>
                </ul>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='eligibility-first'>
                <h5>DOCUMENTS REQUIRED</h5>
                <hr />
                <ul>
                  <li>Completely filled personal loan application. Indian resident.</li>
                  <li>PAN Card.</li>
                  <li>Residence proof – Passport, Driving Licence, Voter ID, Postpaid/Landline Bill, Utility Bills (Electricity/Water/Gas).</li>
                  <li>Bank statements for the last 3 months of salary account..</li>
                  <li>Salary Slips of last 6 months.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      
    </>
  )
}

export default Home



