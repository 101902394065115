import {FaBars, FaTimes} from "react-icons/fa";
import React, {useRef} from 'react';
import { useNavigate } from "react-router-dom";
import "./navbar.css";
function Navbar() {
    const navigate = useNavigate()
   const navRef= useRef();

   const showNavbar=()=>{
    navRef.current.classList.toggle("responsive_nav");

   }
  return (
   <>
   
   <header>
    <h3>LOGO</h3>
    <nav ref={navRef}>
        <a onClick={() => navigate("/")}>Home</a> 
        <a onClick={() => navigate("/about-us")}>About Us</a> 
        <a onClick={() => navigate("/services")}>Services</a> 
        <a onClick={() => navigate("/apply-now")}>Apply Now</a> 
        <a onClick={() => navigate("/founder")}>Meet Our Founders</a> 
        <a onClick={() => navigate("/contact-us")}>Contact Us</a> 
        <button className="nav-btn nav-close-btn" onClick={showNavbar}>
         <FaTimes />
        </button>
    </nav>
    <button className="nav-btn" onClick={showNavbar}>
        <FaBars />
    </button>
   </header>

   </>
  )
}

export default Navbar