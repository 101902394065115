import React from 'react'
import "./service.css";
import LoanMeet from "../../assets/loan-to-meet.jpg";
import HomeImg from "../../assets/small-home-renovation.jpg";
import MedicalImg from "../../assets/medical-emergency.jpg";
import ShoppingImg from "../../assets/shopping.jpg";
import BillsImg from "../../assets/bills.jpg";


function Service() {
    return (
        <>

            <div className='services-head text-center'>
                <h4>Our Services</h4>
                <p>Apart from household needs, our loans can be used for several other purposes</p>
            </div>

            <div className='container-sec-services'>
                <div className='row'>
                    <div className='col-lg-7'>
                        <div className='loan-to-meet-img'>
                            <img src={LoanMeet} alt='' />
                        </div>
                    </div>
                    <div className='col-lg-5'>
                        <div className='loan-to-meet-text'>
                            <h4>LOAN TO MEET FINANCIAL OBLIGATIONS</h4>
                            <p>One can have a hefty credit card bills. Making repayments on a credit card with a high outstanding balance can be troublesome. What is the best course of action in this situation? The solution is simple: taking out a personal loan to pay off a high-interest credit card bill.</p>
                            <p>It is a convenient method for resolving credit card difficulties. The longer you put off paying a large credit card bill, the worse your credit score will be. Taking up a personal loan from Loan Bazar is a convenient solution to address this credit card issue while paying low-interest rates.</p>
                            <button className='apply-for-loan'>Apply For Loan</button>
                        </div>
                    </div>
                </div>
                <div className='row second-services'>
                    <div className='col-lg-5'>
                        <div className='loan-to-meet-text'>
                            <h4>SMALL HOME RENOVATIONS</h4>
                            <p>Loan Bazar offers you instant short-term loans in case you need to fix certain areas of your home.</p>
                            <p>You can use the funds from this loan to repair and renovate your current home/apartment, and repay as soon as you get your salary.</p>
                            <p>Our loans are convenient, with swift processing and minimum paperwork.</p>
                            <button className='apply-for-loan'>Apply For Loan</button>
                        </div>
                    </div>
                    <div className='col-lg-7'>
                        <div className='loan-to-meet-img'>
                            <img src={HomeImg} alt='' className='' />
                        </div>
                    </div>
                </div>

                <div className='row second-services'>
                    <div className='col-lg-7'>
                        <div className='loan-to-meet-img'>
                            <img src={MedicalImg} alt='' className='' />
                        </div>
                    </div>
                    <div className='col-lg-5'>
                        <div className='loan-to-meet-text'>
                            <h4>LOAN FOR MEDICAL EMERGENCY</h4>
                            <p>Medical emergencies are one of the most common reasons people apply for personal loans. Even those with health insurance are experiencing problems paying their obligations due to increased health care expenditures.</p>
                            <p>Medical bills may exceed your insurance coverage, or you may be responsible for paying hospital bills for elderly parents who are not covered by insurance. An instant personal loan can be a lifeline in such circumstances. When you apply for an instant personal loan using a digital service like Loan Bazar, you will obtain the money in your account quickly, so you can use it when you need it most.</p>
                            <button className='apply-for-loan'>Apply For Loan</button>
                        </div>
                    </div>

                </div>

                <div className='row second-services'>
                    <div className='col-lg-5'>
                        <div className='loan-to-meet-text'>
                            <h4>LOAN FOR IMMEDIATE PURCHASING/SHOPPING</h4>
                            <p>Shopping is directly linked to our positive emotions. You may run out of money to replace your old appliances at times. A Personal Loan for Shopping is a lifesaver because it allows you to buy anything that you want immediately while paying off your debts later.</p>
                            <p>Personal shopping loans are unsecured loans that are ideally suited for shopping in any location and outlet. Our shopping loan brings forth an impressive solution for all your immediate financial requirements.</p>
                            <button className='apply-for-loan'>Apply For Loan</button>
                        </div>
                    </div>
                    <div className='col-lg-7'>
                        <div className='loan-to-meet-img'>
                            <img src={ShoppingImg} alt='' className='' />
                        </div>
                    </div>
                </div>

                <div className='row second-services'>
                    <div className='col-lg-7'>
                        <div className='loan-to-meet-img'>
                            <img src={BillsImg} alt='' className='' />
                        </div>
                    </div>
                    <div className='col-lg-5'>
                        <div className='loan-to-meet-text'>
                            <h4>LOAN TO CLEAR BILLS</h4>
                            <p>Many times, paying bills can amount to a decrease in funds. Planning how to handle tough situations can prove to be overwhelming while managing work and household expenses. Cash crunches can disrupt your plans. Paying your rent or utility bills such as phone, power, LPG connection, cable, or Internet bills can reap the benefits of this service in a couple of minutes.</p>
                            <p>You can receive funding into your bank account immediately by simply sitting at home. So, the next time you need money for your monthly expenditures, apply for an XpressLoan to pay off your financial obligations immediately at ease.</p>
                            <button className='apply-for-loan'>Apply For Loan</button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Service