import React from 'react';
import "./about.css";
import SearchUs from "../../assets/search-us.png";
import { ImBullhorn } from "react-icons/im";
import VisionImg from "../../assets/vision-img.png";
import MissionImg from "../../assets/mission-img.png";

function About() {
  return (
    <>

      <div className='about-banner'>
        <h2>About Loan Bazar</h2>
      </div>

      <div className='container'>
        <div className='AboutQuote'>
          <h3>"we provide financial freedom in a much smoother and efficient manner."</h3>
        </div>
      </div>

      <div className='container'>
        <div className='row'>
          <div className='col-lg-5'>
            <div className='about-us-img'>
              <img src={SearchUs} alt='' />
            </div>
          </div>

          <div className='col-lg-7'>
            <div className='about-text'>
              <ImBullhorn />
              <h4>Who Are We?</h4>
              <h5>Loan Bazar is an online platform which you can approach confidently whenever you find yourself without cash at any time during the month.</h5>
              <p>It can be daunting to have no money in the household with salary date still far away. This is exactly where we come in.</p>
              <p>This is not all. We are always there when you are in need of immediate funds, provided your repayment record is clean.</p>
            </div>
          </div>
        </div>
      </div>
      <div className='container-sec'>
        <div className='vision-top-sec'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='vision-text'>
                <h4>Our Vision</h4>
                <p>Our goal is to provide our cherished consumers with a genuine enriching experience through our unique and creative teamwork. As pioneers in the FinTech sector, we want to build a moral and responsible connection to give our clients more worthwhile prospects. Along with offering financial assistance, we also work to provide our customers the tools they need to realize their full potential.</p>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='vision-img'>
                <img src={VisionImg} alt='' />
              </div>
            </div>

          </div>
        </div>

        <div className='container-sec-mission'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='mission-img'>
                <img src={MissionImg} alt='' />
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='mission-text'>
                <h4>Our Mission</h4>
                <p>Today, one needs a full digital and futuristic environment in the comfort of their home. And we here give you and your loved one's additional security and a stable financial future! True friends always lend a hand! If you are unsure of who to call in the event of an emergency financial shortage, Bharat Loan is here to help! For you, we want borrowing to be simple and painless. At Bharat Loan, this is at the core of all we do.</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default About