import React from 'react'
import "./footer.css"
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { IoLocateSharp } from "react-icons/io5";
import { MdOutlineSendToMobile } from "react-icons/md";
import { MdMailOutline } from "react-icons/md";
import { Link } from 'react-router-dom';
function Footer() {
    return (
        <>
            <div className='footer-main'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div className='footer-one'>
                                <h3>Logo</h3>
                                <p>Loan Bazar offer loans up to ₹ 5,00,000 with repayment periods up-to 36 months. All loans are paid through Equal Monthly Instalments (EMIs) via electronic payment. We do charge low platform fees and have no other hidden costs.</p>
                                <div className='d-flex'>
                                   <FaFacebookF /> <FaLinkedinIn /> <FaInstagram /> <FaTwitter />
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-2'>
                            <div className='footer-two'>
                                <h4>Quick Links</h4>
                                <ul className='list-unstyled'>
                                    <li>About Us</li>
                                    <li>Services</li>
                                    <li>Services</li>
                                    <li>FAQ's</li>
                                    <li>Contact Us</li>
                                </ul>
                            </div>
                        </div>

                        <div className='col-lg-3'>
                            <div className='footer-two'>
                                <h4>Helps & Support</h4>
                                <ul className='list-unstyled'>
                                    <li>Privacy Policy</li>
                                    <li>Terms & Conditions</li>
                                    <li>Disclaimer</li>
                                    <li>Disclouser</li>
                                    <li>Loan Calculator</li>
                                </ul>
                            </div>
                        </div>

                        <div className='col-lg-3'>
                            <div className='footer-last'>
                                <h4>Get in Touch</h4>
                                <ul className='list-unstyled'>
                                    <li className='d-flex'> <i><IoLocateSharp /> </i>   G 70, G block Sector 63 Noida, Uttar Paradesh – 201301, India.</li>
                                    <li className='d-flex'><MdOutlineSendToMobile /> + 91 89294-45108</li>
                                    <li className='d-flex'><MdMailOutline /> info@loanbazar.com</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <hr className='mt-'></hr>

                    <div className='terms-condition'>
                    &copy;2024. All rights reserved. Terms & Conditions. Privacy Policy.
                </div>
                </div>
            </div>

        </>
    )
}

export default Footer