import React from 'react'
import "./Founder.css";
import ChiragImg from "../../assets/chirag-gupta.jpeg";
import { IoLogoLinkedin } from "react-icons/io5";
function Founder() {
    return (
        <>
            <div className='container'>
                <div className='founder-main'>
                    <h4>Meet Our Founders</h4>
                </div>
                <div className='row'>
                    <div className='col-lg-7 founder-gap'>
                        <div className='founder-text'>
                            <h5>Vipin Tyagi, <br /> Founder and CEO, <br /> Loan Bazar</h5>
                            <hr className='text-danger' />
                            <p>Vipin Tyagi has over 20 years of experience in investment banking and private equity. Before founding Loan Bazar, Tushar was Executive Director at Everstone Capital, a private equity firm based out of Noida where he led the firm’s investments in Northern India across retail and financial services. His prior board affiliations include. Prior to Everstone, Vipin was a private equity investor with General Atlantic based out of New York, investing in areas of business services and technology. Prior to General Atlantic, Vipin was an investment banker in the mergers and acquisitions team of Lehman Brothers based out of New York. Vipin started his career with Goldman Sachs in New York. He holds a BE in Electrical Engineering from Stony Brook University, where he graduated Magna cum Laude. Vipin holds an MBA from The Wharton School at the University of Pennsylvania and the CFA charter. He is a member of the Aspen Global Leadership Network and the Aspen Institute.</p>
                        </div>
                    </div>
                    <div className='col-lg-4 founder-gap'>
                        <div className='founder-img'>
                            <img src={ChiragImg} alt='' />
                            <IoLogoLinkedin />
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-lg-7 founder-gap'>
                        <div className='founder-text'>
                            <h5>Chirag Gupta, <br /> Co-founder, <br /> Loan Bazar</h5>
                            <hr className='text-danger' />
                            <p>Chirag has over 20 years of experience in financial services and entrepreneurship. Prior to Loan Bazar, Chirag was the Founder and CEO of Technove Gloabal Software Company Noida, a premier software in the India. He was earlier an investment banker with Merrill Lynch in New York and PwC in India. In her capacity as an investment banker, she has executed transactions worth over $1bn. Chirag is a Chartered Accountant and has completed her Masters from Columbia University and Bachelors from SRCC. She is an active member of FLO, Yi and Ellevate.</p>
                        </div>
                    </div>
                    <div className='col-lg-4 founder-gap'>
                        <div className='founder-img'>
                            <img src={ChiragImg} alt='' />
                            <IoLogoLinkedin className='down-svg'/>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Founder