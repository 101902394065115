import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react"; 
import { Routes, Route } from "react-router-dom";
import Navbar from "./component/navbar/Navbar";
import Home from "../src/pages/home/Home";
import Calculator from './pages/home/Calculator';
import Footer from './component/footer/Footer';
import About from './pages/about-us/About';
import Service from './pages/services/Service';
import Founder from './pages/founder/Founder';
import Contact from './pages/contact-us/Contact';
// import News from "./pages/News";

const App = () => {
 return (
  <>
     <Navbar />
       <Routes>
         <Route path="/" element={<Home />} />
         <Route path='/about-us' element={<About />} />
         <Route path='/services' element={<Service />} />
         <Route path='/founder' element={<Founder />} />
         <Route path='/contact-us' element={<Contact />} />
       </Routes>
       
     <Footer />
   
   </>
 );
};

export default App;
