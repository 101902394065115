import React from 'react'
import "./Contact.css";
import { FcHome } from "react-icons/fc";
import { MdLocationOn } from "react-icons/md";
import { FcCallback } from "react-icons/fc";
import { IoCallOutline } from "react-icons/io5";
import { CiMail } from "react-icons/ci";
import { TbMailPin } from "react-icons/tb";

function Contact() {
    return (
        <>

            <div className='contact-us-banner'>
                <h2>Contact Us </h2>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-4'>
                        <div className='contact-first'>
                            <FcHome />
                            <h4>Office Address</h4>
                            <div className='addres-sec'>
                                <p> <MdLocationOn /> </p>
                                <p>G 70, G block Sector 63 Noida, Uttar Paradesh – 201301, India.</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='contact-first'>
                            <FcCallback />
                            <h4>Contact Us</h4>
                            <div className='addres-sec'>
                                <p> <IoCallOutline /> </p>
                                <p style={{cursor:"pointer"}} >+91 8929445108</p>
                            </div>
                            <div className='addres-sec'>
                                <p><IoCallOutline /> </p>
                                <p style={{cursor:"pointer"}} > +91 8929445104 </p> <br />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='contact-first'>
                            <TbMailPin />
                            <h4>Connect With Email</h4>
                            <div className='addres-sec'>
                                <p> <CiMail /> </p>
                                <p>info@loanbazar.com</p>
                            </div>
                            <div className='addres-sec'>
                                <p> <CiMail /> </p>
                                <p>support@loanbazar.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='email-section'>
                <div className='container-email-section'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='name-input'>
                                <input type='name' placeholder='Name' />
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='name-input'>
                                <input type='email' placeholder='Email Address' />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='name-input'>
                                <input type='phone' placeholder='Enter Phone Number' />
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='name-input'>
                                <input type='subject' placeholder='Subject' />
                            </div>
                        </div>
                    </div>

                    <div className='message-email-through'>
                        <input type='message' placeholder='Enter Your Message Here' />
                    </div>
                    <div className='text-center'>
                        <button className='apply-for-loan'>SEND A MESSAGE</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact